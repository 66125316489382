import * as React from "react"
import tw from "tailwind.macro"
import { useStaticQuery, graphql } from "gatsby"
import { Card, CardName } from "./card"

const Name = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "tooth_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <CardName data={data}>{children}</CardName>
}

const Career = tw.div`
  text-base ml-1
`
const CareerDetail = tw.div`
  text-sm whitespace-pre-line ml-3
`

export { Card, Career, CareerDetail, Name }
