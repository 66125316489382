import * as React from "react"
import Img from "gatsby-image"
import tw from "tailwind.macro"
import styled from "@emotion/styled"

const Card = styled.div`
  border-radius: 4px;
  background: #666;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  height: 382px;
  width: 300px;
  margin: 1rem;
`

const NameContainer = tw.div`
   m-2 flex items-center
`

const NameText = tw.div`
  text-xl ml-2 font-bold
`
const CardName = ({ data, children }) => {
  return (
    <NameContainer>
      <Img
        fluid={data.icon.childImageSharp.fluid}
        style={{
          height: "1.6rem",
          width: "1.6rem",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      />
      <NameText>{children}</NameText>
    </NameContainer>
  )
}

export { Card, CardName }
