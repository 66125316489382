import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import moment from "moment"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Title = styled.h2`
  color: #68b7c3;
  user-select: none;
  text-shadow: 2px 2px 5px rgba(41, 72, 76, 0.4);
  ${tw`text-3xl mb-2`};
`

const AppointmentContainer = tw.div`
  flex flex-col items-center
`

const AppointmentListContainer = tw.div`
  flex flex-col justify-between
`

const AppointmentOnlineBooking = tw.div`
  flex flex-col
`

const AppointmentStepCaption = tw.div`
  flex justify-center m-1
`

const AppointmentSteps = tw.div`
  flex justify-center
`

const AppointmentStepContainer = tw.div`
  flex flex-col items-center
`

const AppointmentCaption = tw.div`
  text-gray-600 text-xs
`

const AppointmentStep = ({ caption, fixed }) => {
  return (
    <AppointmentStepContainer>
      <AppointmentCaption>{caption}</AppointmentCaption>
      <Img fixed={fixed} />
    </AppointmentStepContainer>
  )
}

const AppointmentListItem = ({ title, children }) => {
  return (
    <ListItem>
      <ListIcon />
      <div style={{ width: "7rem" }}>{title}</div>
      {children}
    </ListItem>
  )
}

const ListItem = styled.div`
  ${tw`flex items-center m-2`}
`

const ListIcon = styled.div`
  ${tw` h-4 w-1 m-1`}
  background-color: #68B7C3;
`

const Book = styled.div`
  ${tw`border-solid border-2 border-green-400 flex justify-between items-center text-green-400 p-1 text-lg hover:bg-green-100 pl-2 pr-1 hover:shadow-md rounded`}
  width: 15.2rem;
  transition: all 0.2s ease-in-out;
`

const BookClick = styled.div`
  ${tw`bg-green-400 text-white p-1 text-xl rounded w-16 flex justify-center font-bold`}
`

const TimeLabel = styled.span`
  ${tw`text-sm`}
  @media screen and (min-width: 600px) {
    ${tw`text-base`}
  }
`

export const UnderlineStyle = {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #68d391",
  boxShadow: "0 -4px 0 #68d391 inset",
  color: "inherit",
}

const TimeTableItem = ({ day, time, isToday }) => {
  const style = isToday ? UnderlineStyle : { display: "flex" }

  return (
    <div style={style}>
      <span style={{ width: "4rem" }}>{day}</span>
      <TimeLabel>{time}</TimeLabel>
    </div>
  )
}

const TimeTable = () => {
  const [date, setDate] = React.useState(moment())

  React.useEffect(() => {
    const timerID = setInterval(() => setDate(moment()), 1000)
    return function cleanup() {
      clearInterval(timerID)
    }
  }, [])

  const weekNumber = moment(date).day()

  return (
    <div style={{ marginLeft: "1px" }}>
      <TimeTableItem
        day="月"
        time="9:00〜12:00/14:00〜18:30"
        isToday={weekNumber == 1}
      ></TimeTableItem>
      <TimeTableItem
        day="火"
        time="9:00〜12:00/14:00〜18:30"
        isToday={weekNumber == 2}
      ></TimeTableItem>
      <TimeTableItem
        day="水"
        time="9:00〜12:00/14:00〜18:30"
        isToday={weekNumber == 3}
      ></TimeTableItem>
      <TimeTableItem
        day="木"
        time="9:00〜12:00"
        isToday={weekNumber == 4}
      ></TimeTableItem>
      <TimeTableItem
        day="金"
        time="9:00〜12:00/14:00〜18:30"
        isToday={weekNumber == 5}
      ></TimeTableItem>
      <TimeTableItem
        day="土"
        time="9:00〜12:00"
        isToday={weekNumber == 6}
      ></TimeTableItem>
      <TimeTableItem
        day="日"
        time="定休日"
        isToday={weekNumber == 7}
      ></TimeTableItem>
    </div>
  )
}

const Appointment = ({ toRef }) => {
  const data = useStaticQuery(graphql`
    query {
      step1: file(relativePath: { eq: "appointment_step1.png" }) {
        childImageSharp {
          fixed(width: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      step2: file(relativePath: { eq: "appointment_step2.png" }) {
        childImageSharp {
          fixed(width: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      step3: file(relativePath: { eq: "appointment_step3.png" }) {
        childImageSharp {
          fixed(width: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      step4: file(relativePath: { eq: "appointment_step4.png" }) {
        childImageSharp {
          fixed(width: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <AppointmentContainer ref={toRef} id="appointment">
      <Title>予約/受付</Title>
      <AppointmentListContainer style={{ gap: "1.5rem" }}>
        <AppointmentListItem title="ネット予約">
          <AppointmentOnlineBooking>
            <OutboundLink
              href="https://ssl.haisha-yoyaku.jp/s1521118/login/serviceAppoint/index?SITE_CODE=hp"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <Book>
                <div style={{ fontWeight: "bold" }}>24時間︎受付中</div>
                <BookClick>{`>`}</BookClick>
              </Book>
            </OutboundLink>
            <AppointmentStepCaption>簡単4ステップ</AppointmentStepCaption>
            <AppointmentSteps>
              <AppointmentStep
                caption="診療内容"
                fixed={data.step1.childImageSharp.fixed}
              />
              <AppointmentStep
                caption="予約日時"
                fixed={data.step2.childImageSharp.fixed}
              />
              <AppointmentStep
                caption="本人情報"
                fixed={data.step3.childImageSharp.fixed}
              />
              <AppointmentStep
                caption="確定"
                fixed={data.step4.childImageSharp.fixed}
              />
            </AppointmentSteps>
          </AppointmentOnlineBooking>
        </AppointmentListItem>
        <AppointmentListItem title="電話予約">
          <a href="tel:059-336-5151" style={{ textDecoration: "none" }}>
            <Book>
              <div style={{ fontWeight: "bold" }}>059-336-5151</div>
              <BookClick>{`>`}</BookClick>
            </Book>
          </a>
        </AppointmentListItem>
        <AppointmentListItem title="診療時間">
          <TimeTable />
        </AppointmentListItem>
      </AppointmentListContainer>
    </AppointmentContainer>
  )
}

export default Appointment
