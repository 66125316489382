import * as React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import Marker from "./marker"

const Title = styled.h2`
  user-select: none;
  color: #68b7c3;
  text-shadow: 8px 8px #eee;
  ${tw`text-2xl tracking-wide mt-3 mb-4 text-center`}
  @media screen and (min-width: 600px) {
    ${tw`text-6xl`}
  }
`

const TitleItemContainer = tw.div`
 flex items-center flex-col ml-8 mr-8
`

const TitleItem = styled.div`
  ${tw`text-base pb-2`}
  @media screen and (min-width: 600px) {
    ${tw`text-xl`}
  }
`

const TitleContent = () => {
  return (
    <>
      <Title>私たちの治療に対する考え方</Title>
      <TitleItemContainer>
        {[
          <span>
            ①<Marker>丁寧な説明 、納得の出来るカウンセリング</Marker>
            、前もっての治療費の説明をする。
          </span>,
          <span>
            ②ご希望をお聞きした上での
            <Marker>治療法の 提示、相談のうえ最適な治療法を選択</Marker>する。
          </span>,
          <span>
            ③残っている歯を１本でも多く、<Marker>少しでも長く機能させる</Marker>
            。神経をなるべく残す。歯質を残す。
          </span>,
          <span>
            ④ちょっとした<Marker>習慣の見直しなど、予防を大切に</Marker>
            しています。
          </span>,
          <span>
            ⑤気軽に相談いただける<Marker>身近で、 確かな技術</Marker>
            を持って信頼のおける歯科医院を目指しております。
          </span>,
        ].map((item, index) => (
          <TitleItem key={"title" + index}>{item}</TitleItem>
        ))}
      </TitleItemContainer>
    </>
  )
}

export default TitleContent
