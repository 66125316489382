import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import Header from "./header"
import Title from "./title"
import Stuffs from "./stuffs"
import Appointment from "./appointment"
import Map from "./map"
import Treatment from "./treatments"

const WhiteContainer = styled.div`
  margin: 0 auto;
  color: #666;
  margin-bottom: 2rem;
`

const ThemeContainer = styled(WhiteContainer)`
  background: radial-gradient(circle 600px at 22% -200px, #b9f8fa, #68b7c3);
`

const TitleContainer = styled(WhiteContainer)``
const StuffContainer = styled(ThemeContainer)``
const AppointmentContainer = styled(WhiteContainer)``
const MapContainer = styled(ThemeContainer)``
const TreatmentContainer = styled(WhiteContainer)`
  margin-bottom: 3rem;
`

const Layout = ({ children }) => {
  const stuffRef = React.useRef()
  const appointmentRef = React.useRef(null)
  const treatmentRef = React.useRef(null)
  const accessRef = React.useRef(null)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        stuffRef={stuffRef}
        appointmentRef={appointmentRef}
        treatmentRef={treatmentRef}
        accessRef={accessRef}
      />
      <TitleContainer>
        <Title />
      </TitleContainer>
      <StuffContainer>
        <Stuffs toRef={stuffRef} />
      </StuffContainer>
      <AppointmentContainer>
        <Appointment toRef={appointmentRef} />
      </AppointmentContainer>
      <MapContainer>
        <Map toRef={accessRef} />
      </MapContainer>
      <TreatmentContainer>
        <Treatment toRef={treatmentRef} />
      </TreatmentContainer>
      <main>{children}</main>
      <footer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          @links
          <a
            href="https://haisha-yoyaku.jp/bun2sdental/detail/index/id/2430202495/"
            style={{ marginLeft: "1rem" }}
          >
            口コミサイト
          </a>
          <a
            href="https://instagram.com/oiwakeshika"
            style={{ marginLeft: "1rem" }}
          >
            Instagram
          </a>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
