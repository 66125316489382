import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import BackgroundImage from "gatsby-background-image"

import HeaderCircle from "./headerCircle"

const Container = styled.div`
  background: radial-gradient(circle 600px at 22% -200px, #b9f8fa, #68b7c3);
  height: 500px;
`

const Name = styled.h1`
  position: absolute;
  top: 40px;
  left: 10%;
  user-select: none;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.4);
  ${tw`text-white text-4xl`};
`

const HeaderBackground = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "header-background.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledBackgroundImage fluid={data.placeholderImage.childImageSharp.fluid}>
      {children}
    </StyledBackgroundImage>
  )
}

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: top right;
  background-size: contain;
`
const scrollToRef = ref => () => window.scrollTo(0, ref.current.offsetTop)

const HeaderComponent = ({
  siteTitle,
  stuffRef,
  appointmentRef,
  treatmentRef,
  accessRef,
}) => {
  return (
    <header>
      <Container>
        <HeaderBackground>
          <Name>おいわけ歯科(Oiwake Dental Clinic)</Name>
          <nav>
            <ul>
              <HeaderCircle
                left="10%"
                top="250px"
                size={140}
                onClick={scrollToRef(appointmentRef)}
              >
                ネット予約|電話
              </HeaderCircle>
              <HeaderCircle
                left="22%"
                top="160px"
                size={160}
                onClick={scrollToRef(stuffRef)}
              >
                スタッフの紹介
              </HeaderCircle>
              <HeaderCircle
                left="20%"
                top="350px"
                size={140}
                onClick={scrollToRef(treatmentRef)}
              >
                治療に関して
              </HeaderCircle>
              <HeaderCircle
                left="37%"
                top="320px"
                size={130}
                onClick={scrollToRef(accessRef)}
              >
                交通案内
              </HeaderCircle>
            </ul>
          </nav>
        </HeaderBackground>
      </Container>
    </header>
  )
}

export default HeaderComponent
