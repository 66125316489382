import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import Marker from "./marker"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import { Card, CardName } from "./card"

SwiperCore.use([Navigation])

const Title = styled.h2`
  user-select: none;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.4);
  ${tw`text-white text-3xl mt-4`};
`

const MapContainer = tw.div`
  flex flex-col items-center
`

const CardContainer = styled.div`
  ${tw`flex flex-wrap items-center justify-center`}
`

const CardDetail = tw.div`
  text-sm ml-4
`

const Map = ({ toRef }) => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "map_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      outside: file(relativePath: { eq: "outside.jpg" }) {
        childImageSharp {
          fixed(height: 200, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      parking: file(relativePath: { eq: "parking.png" }) {
        childImageSharp {
          fixed(height: 200, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      secondParking: file(relativePath: { eq: "second_parking.png" }) {
        childImageSharp {
          fixed(height: 200, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      station: file(relativePath: { eq: "station.png" }) {
        childImageSharp {
          fixed(height: 200, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      inside: file(relativePath: { eq: "inside.jpg" }) {
        childImageSharp {
          fixed(height: 200, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      equipment: file(relativePath: { eq: "equipment.jpg" }) {
        childImageSharp {
          fixed(height: 200, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      operationRoom: file(relativePath: { eq: "operation_room.jpg" }) {
        childImageSharp {
          fixed(height: 200, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <MapContainer ref={toRef}>
      <Title>交通案内</Title>
      <CardContainer>
        <Card>
          <CardName data={data}>地図</CardName>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13083.409062625893!2d136.5944382!3d34.9352419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60038e9f18953b63%3A0xb16e8b25f3735e3f!2sOiwake%20Dental%20Clinic!5e0!3m2!1sen!2suk!4v1706356108116!5m2!1sen!2suk"
            // width="600"
            height="200"
            style={{ border: "0" }}
            // @ts-expect-error
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <CardDetail>〒510-0893 三重県四日市市前田町23-13</CardDetail>
        </Card>
        <Card>
          <CardName data={data}>外観/駐車場</CardName>
          <Swiper
            scrollbar={{ draggable: true }}
            direction="horizontal"
            loop
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            <SwiperSlide>
              <Img fixed={data.outside.childImageSharp.fixed} />
            </SwiperSlide>
            <SwiperSlide>
              <Img fixed={data.parking.childImageSharp.fixed} />
            </SwiperSlide>
            <SwiperSlide>
              <Img fixed={data.secondParking.childImageSharp.fixed} />
            </SwiperSlide>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </Swiper>
          <CardDetail>
            <Marker>当院前に駐車場4台</Marker>、<Marker>駐輪場</Marker>
            ございます
            <br />
            徒歩1分に<Marker>第二駐車場3台</Marker>
            もございます(写真2,3枚目の5,6,7番)
          </CardDetail>
        </Card>
        <Card>
          <CardName data={data}>アクセス</CardName>
          <Img fixed={data.station.childImageSharp.fixed} />
          <CardDetail>
            <Marker>内部線追分駅(三重県) 出口徒歩5分</Marker> <br />
            関西本線南四日市駅 出口車7分 <br />
            近鉄名古屋線塩浜駅 出口車10分 <br />
            内部線泊駅(三重県) 出口車3分 <br />
            内部線小古曽駅 出口車6分 <br />
            近鉄名古屋線四日市駅 出口車15分
          </CardDetail>
        </Card>
        <Card>
          <CardName data={data}>館内</CardName>
          <Swiper
            scrollbar={{ draggable: true }}
            direction="horizontal"
            loop
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            <SwiperSlide>
              <Img fixed={data.inside.childImageSharp.fixed} />
            </SwiperSlide>
            <SwiperSlide>
              <Img fixed={data.operationRoom.childImageSharp.fixed} />
            </SwiperSlide>
            <SwiperSlide>
              <Img fixed={data.equipment.childImageSharp.fixed} />
            </SwiperSlide>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </Swiper>
          <CardDetail>
            館内はきれいに整頓されており、最新の器具が揃えられています
          </CardDetail>
        </Card>
      </CardContainer>
    </MapContainer>
  )
}

export default Map
