import * as React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import { UnderlineStyle } from "./appointment"

const Title = styled.h2`
  color: #68b7c3;
  user-select: none;
  text-shadow: 2px 2px 5px rgba(41, 72, 76, 0.4);
  ${tw`text-3xl mb-2`};
`

const TreatmentContainer = tw.div`
  flex flex-col items-center
`

const TreatmentItemContainer = tw.div`
 flex items-center ml-8 mr-8 whitespace-no-wrap flex-wrap
`

const TreatmentWithExplanation = styled.div`
  max-width: 600px;
  ${tw`flex flex-col pb-4 pl-4 pr-4`};
`

const TreatmentItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #68d391;
  box-shadow: 0 -4px 0 #68d391 inset;
  color: inherit;
  ${tw`text-xl mr-4 text-pink-500`};
`

export default ({ toRef }) => {
  return (
    <TreatmentContainer ref={toRef}>
      <Title>治療</Title>
      <TreatmentWithExplanation>
        <TreatmentItem>虫歯</TreatmentItem>
        残っている歯を１本でも多く、少しでも長く機能させる。神経をなるべく残す。歯質を残す。
        <br />
        自分の歯に勝る噛みごたえは他のものでは得られません。神経が通っている歯を、いかにしてその神経を残す治療ができるかを心がけています。また、歯の根っこの部分を少しでも残すことができれば、歯の土台部分(骨)がやせにくくなり、入れ歯を安定させられます。ですので、患者さま自身の歯をなるべく大切にしています。
      </TreatmentWithExplanation>
      <TreatmentWithExplanation>
        <TreatmentItem>予防歯科</TreatmentItem>
        当院は、虫歯や歯周病の原因について丁寧にご説明しています。せっかく治療をしても、症状の根本的な原因を除去できないと、再治療になってしまうからです。
        <br />
        また、患者さまのお口の状態に合わせて、セルフケアについてアドバイスしています。手軽にケアができる電動歯ブラシを紹介したり、予防の働きを高めるマウスウォッシュをおすすめしたりして、ご自身でお口の健康管理ができるようにサポートさせていただきます。
      </TreatmentWithExplanation>
      <TreatmentItemContainer>
        {[
          "歯科",
          "小児歯科",
          "矯正歯科",
          "ホワイトニング",
          "インプラント",
          "噛み合わせ",
          "親知らず",
        ].map((item, index) => (
          <TreatmentItem key={"treatment" + index}>{item}</TreatmentItem>
        ))}
      </TreatmentItemContainer>
    </TreatmentContainer>
  )
}
