import * as React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"

type PositionProps = {
  left: string
  top: string
  size: string
}

type SizeProps = {
  size: string
}

type Props = {
  left: string
  top: string
  size: number
  onClick: () => void
}

const Outside = styled.li<PositionProps>`
  transition: 0.8s;
  position: absolute;
  left: ${props => props.left};
  top: ${props => props.top};
  width: ${props => props.size};
  height: ${props => props.size};

  background: rgba(255, 255, 255, 0.25) padding-box;
  border-radius: 50%;
  ${tw`flex items-center justify-center`};

  &:hover {
    border: 0px solid #68b7c3; /*枠線の幅、線種、色*/
    background: rgba(255, 255, 255, 0.25) padding-box;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.8);
  }
`

const Inside = styled.a<SizeProps>`
  width: ${props => props.size};
  height: ${props => props.size};
  font-family: "hiragino kaku gothic pro", sans-serif;
  font-size: 12px;
  font-weight: 100;
  transition: 0.5s;

  background: rgba(255, 255, 255, 0.55) padding-box;
  border: 10px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  user-select: none;
  ${tw`text-gray-500 whitespace-no-wrap flex items-center justify-center`};

  &:hover {
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.8);
    background: #fff padding-box;
    color: #68b7c3;
  }
`

const HeaderCircle: React.SFC<Props> = ({
  children,
  top,
  left,
  size,
  onClick,
}) => {
  const outer = size + "px"
  const inner = size - 20 + "px"

  return (
    <Outside size={outer} top={top} left={left} onClick={onClick}>
      <Inside size={inner}>{children}</Inside>
    </Outside>
  )
}

export default HeaderCircle
