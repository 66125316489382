import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Card, Career, CareerDetail, Name } from "./stuff"

const Stuff = () => {
  const data = useStaticQuery(graphql`
    query {
      stuff: file(relativePath: { eq: "stuff2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Card>
      <Name>小川 彰子</Name>
      <Img
        fluid={data.stuff.childImageSharp.fluid}
        style={{ height: "200px" }}
      />
      <Career>経歴</Career>
      <CareerDetail>平成2年3月九州歯科大学卒業</CareerDetail>
    </Card>
  )
}

export default Stuff
