import * as React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"

import Stuff1 from "./stuff1"
import Stuff2 from "./stuff2"
import Stuff3 from "./stuff3"

const Title = styled.h2`
  user-select: none;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.4);
  ${tw`text-white text-3xl mt-4`};
`

const StuffsContainer = styled.div`
  ${tw` flex flex-col items-center`}
`

const StuffContainer = styled.div`
  ${tw`flex flex-wrap items-center justify-center`}
`

const Stuffs = ({ toRef }) => {
  return (
    <StuffsContainer ref={toRef}>
      <Title>スタッフ紹介</Title>
      <StuffContainer>
        <Stuff1 />
        <Stuff2 />
      </StuffContainer>
    </StuffsContainer>
  )
}

export default Stuffs
