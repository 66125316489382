import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Card, Career, CareerDetail, Name } from "./stuff"

const Stuff = () => {
  const data = useStaticQuery(graphql`
    query {
      stuff: file(relativePath: { eq: "stuff1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Card>
      <Name>小川 浩志(院長)</Name>
      <Img
        fluid={data.stuff.childImageSharp.fluid}
        style={{ height: "200px" }}
      />
      <Career>経歴</Career>
      <CareerDetail>
        平成2年3月 九州歯科大学卒業 <br />
        愛知県・三重県の歯科医院に勤務 <br />
        平成6年〜平成29年8月 大久保歯科医院を開院 <br />
        平成29年9月〜 おいわけ歯科を開院
      </CareerDetail>
    </Card>
  )
}

export default Stuff
